import { createRouter, createWebHistory } from 'vue-router'
import HomeView from '../components/Home/homePage.vue'

const routes = [
  {
    path: '/',
    name: 'home',
    component: HomeView
  },
  {
    path: '/aboutus',
    name: 'aboutus',
    component: () => import('../components/aboutus/aboutFinal.vue')
  },
  {
    path: '/first',
    name: 'first',
    component: () => import('../components/Home/firstSection.vue')
  },
  {
    path: '/second',
    name: 'second',
    component: () => import('../components/Home/secondSection.vue')
  },
  {
    path: '/intropage',
    name: 'intropage',
    component: () => import('../components/Home/introPage.vue')
  },
  {
    path: '/thirdsection',
    name: 'thirdsection',
    component: () => import('../components/Home/thirdSection.vue')
  },
  {
    path: '/fourthsection',
    name: 'fourthsection',
    component: () => import('../components/Home/fourthSection.vue')
  },
  {
    path: '/fifthsection',
    name: 'fifthsection',
    component: () => import('../components/Home/fifthSection.vue')
  },
  {
    path: '/sixthSection',
    name: 'sixthSection',
    component: () => import('../components/Home/sixthSection.vue')
  },
  {
    path: '/prayerreq',
    name: 'prayerreq',
    component: () => import('../components/prayerrequest/prayerRequest.vue')
  },
  {
    path: '/prayertiming',
    name: 'prayertiming',
    component: () => import('../components/prayerrequest/prayerTimings.vue')
  },
  {
    path: '/contactus',
    name: 'contactus',
    component: () => import('../components/contactus/contactPage.vue')
  },
  {
    path: '/aboutpage',
    name: 'aboutpage',
    component: () => import('../components/aboutus/aboutPage.vue')
  },
  {
    path: '/churchHis',
    name: 'churchHis',
    component: () => import('../components/aboutus/churchHis.vue')
  },
  {
    path: '/gallery',
    name: 'gallery',
    component: () => import('../components/gallery/galleryPage.vue')
  },
  {
    path: '/stantony',
    name: 'stantony',
    component: () => import('../components/stAntony/stAntony.vue')
  },
  {
    path: '/vicarsmessage',
    name: 'vicarmessage',
    component: () => import('../components/vicar/vicarMessage.vue')
  },
  
]

const router = createRouter({
  history: createWebHistory(),
  routes
})

export default router
