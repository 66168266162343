<template>
  <div>
    <!-- Desktop Navigation -->
    <v-app-bar app class="hidden-sm-and-down" height="100%">
      <v-container>
        <v-row justify="start">
          <v-col cols="12" class="text-left" lg="3" md="1">
            <v-img
              src="../../assets/images/logo.png"
              height="100%"
              contain
            ></v-img>
          </v-col>
          <v-col
            cols="12"
            lg="9"
            align-self="center"
            md="11"
            class="text-right interregular"
          >
            <v-row justify="end">
              <v-col cols="2" v-for="(item, index) in navItems" :key="index">
                  <span v-if="item.label == 'ABOUT US'">
                  <v-menu transition="scale-transition">
                    <template v-slot:activator="{ props }">
                      <v-btn v-bind="props" variant="text"
                    elevation="0"
                    class="hdr"> ABOUT US &nbsp; &nbsp;</v-btn>
                    </template>

                    <v-list>
                      <v-list-item v-for="(item, i) in items" :key="i" @click="redirectToPage(item.route)">
                        <v-list-item-title>{{ item.title }}</v-list-item-title>
                      </v-list-item>
                    </v-list>
                  </v-menu>
                </span>
                <span v-else>
                  <v-btn
                    @click="scrollToRoute(item.route)"
                    :to="item.route"
                    :class="setActiveButton(item.route)"
                    variant="text"
                    elevation="0"
                    class="hdr"
                  >
                    {{ item.label }}
                  </v-btn>
                </span>
              </v-col>
            </v-row>
          </v-col>
        </v-row>
      </v-container>
    </v-app-bar>

    <!-- Mobile Navigation -->
    <div class="hidden-md-and-up">
      <v-app-bar app class="hidden-md-and-up">
        <v-row justify="space-between">
          <v-col cols="12" class="pl-8" sm="12">
            <v-img src="../../assets/images/logo.png" height="40px"></v-img>
          </v-col>
        </v-row>
        <v-app-bar-nav-icon @click="mobileMenu = !mobileMenu">
          <v-icon color="black">mdi-menu</v-icon>
        </v-app-bar-nav-icon>
      </v-app-bar>
      <v-navigation-drawer
        class="custom-navigation-drawer"
        location="right"
        app
        temporary
        v-model="mobileMenu"
        style="position: fixed"
      >
        <v-list>
          <v-row class="px-8 py-5 pt-16">
            <v-col
              cols="12"
              v-for="(item, index) in navItems"
              :key="index"
              :to="item.route"
              :class="setActiveButton(item.route)"
            >
            <span v-if="item.label == 'ABOUT US'">
                  <v-menu transition="scale-transition">
                    <template v-slot:activator="{ props }">
                      <span v-bind="props" 
                      class="router-link hdr"> ABOUT US &nbsp; &nbsp;   </span>
                    </template>

                    <v-list>
                      <v-list-item v-for="(item, i) in items" :key="i" @click="redirectToPage(item.route)">
                        <v-list-item-title>{{ item.title }}</v-list-item-title>
                      </v-list-item>
                    </v-list>
                  </v-menu>
                </span>
                <span v-else>
                  <router-link :to="item.route" class="router-link">
                <v-list-item-title
                  ><span class="hdr interregular">
                    {{item.label}}</span></v-list-item-title
                >
              </router-link>
                </span>
              
            </v-col>
          </v-row>
        </v-list>
      </v-navigation-drawer>
    </div>
  </div>
</template>

<script>
export default {
  data() {
    return {
      navItems: [
        { label: "HOME", route: "/" },
        { label: "ABOUT US", route: "/aboutus" },
        { label: "PRAYER REQUEST", route: "/prayerreq" },
        { label: "TIMINGS ", route: "/prayertiming" },
        { label: "GALLERY", route: "/gallery" },
        { label: "CONTACT US", route: "/contactus" },
      ],
      items: [
        { title: "HISTORY", route: "/aboutus" },
        { title: "ST. ANTONY", route: "/stantony" },
        { title: "VICAR'S MESSAGE", route: "/vicarsmessage" },
      ],
      mobileMenu: false,
    };
  },
  computed: {
    activeRoute() {
      return this.$route.path;
    },
  },
  methods: {
    setActiveButton(route) {
      return route === this.activeRoute
        ? "active-button active-text"
        : "active-button";
    },
    scrollToRoute(route) {
      this.activeRoute = route;
      this.scroll();
    },
    redirectToPage(route) {
      this.$router.push(route);
      this.scroll();
    },
    scroll() {
      window.scrollTo({
        top: 0,
        behavior: "smooth",
      });
    },
  },
};
</script>

<style scoped>
.custom-navigation-drawer {
  margin-top: 0;
  padding-top: 0;
}
.router-link {
  text-decoration: none;
  color: inherit;
  cursor: pointer;
}
.hdr {
  /* font-family: interregular; */
  font-size: 12px;
  font-weight: 600;
}
.active-button {
  background-color: rgb(255, 255, 255);
  color: rgb(0, 0, 0);
}

.active-text {
  color: rgba(58, 129, 230, 1);
  background-color: white;
}
</style>
