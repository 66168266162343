<template>
    <div class="hidden-sm-and-down">
        <v-row justify="center" style="background-color: rgba(15, 30, 48, 1);">
            <v-col cols="12" class="py-16">
                <v-row justify="center">
                    <v-col cols="10">
                        <v-row justify="center">
                            <v-col cols="12">
                                <v-row justify="center">
                                    <v-col cols="6" lg="4" sm="3" class="pa-0 text-left" align-self="start">
                                        <v-row justify="start">
                                            <v-col cols="12" sm="6" class="text-left" align-self="center">
                                                <v-img src="../../assets/images/logofooter.png">
                                                </v-img>
                                            </v-col>
                                            <v-col cols="12" class="text-left interregular headname" align-self="center">
                                                <span>St Antony's Pilgrim Church, Chengalam</span>
                                            </v-col>
                                            <v-col cols="12" class="text-left interregular subhead" align-self="center">
                                                <span>Chengalam East p.o, Kottayam, Kerala, South India <br>Pin : 686 585</span>

                                            </v-col>
                                            <v-col cols="12" class="text-left interregular subhead" align-self="center">
                                                <span>+91
                                                    828 156 4332<br>0481 270 4332
                                                </span>
                                            </v-col>
                                            <v-col cols="12" class="text-left interregular subhead" align-self="center">
                                                <span>sacchengalam@gmail.com</span>
                                            </v-col>
                                        </v-row>
                                    </v-col>
                                    <v-col cols="12" lg="8" sm="9" align-self="center">
                                        <v-row justify="center">
                                            <v-col cols="7" lg="3" sm="3" align-self="center">
                                                <v-row justify="center" align-self="center">
                                                    <v-col cols="12" class="subone" @click="redirectToMap" style="cursor: pointer;">
                                                        <span>Navigation</span>
                                                    </v-col>
                                                    <v-col cols="12" class="subtwo" @click="redirectToHome" style="cursor: pointer;">
                                                        <span>Home</span>
                                                    </v-col> <v-col cols="12" class="subtwo" @click="redirectToGallery" style="cursor: pointer;">
                                                        <span>Gallery</span>
                                                    </v-col> <v-col cols="12" class="subtwo" style="cursor: pointer;">
                                                        <span>News & Events</span>
                                                    </v-col>
                                                </v-row>
                                            </v-col>
                                            <v-col cols="7" lg="3" sm="3" align-self="center">
                                                <v-row justify="center" align-self="center">
                                                    <v-col cols="12" class="subone" @click="redirectToAbout" style="cursor: pointer;">
                                                        <span>About</span>
                                                    </v-col>
                                                    <v-col cols="12" class="subtwo" @click="redirectToContactus" style="cursor: pointer;">
                                                        <span>Contact Us</span>
                                                    </v-col>
                                                    <v-col cols="12" class="subtwo" @click="redirectToTimings" style="cursor: pointer;">
                                                        <span>Timings</span>
                                                    </v-col>
                                                    <v-col cols="12" class="subtwo" @click="redirectToPrayerreq" style="cursor: pointer;">
                                                        <span>Prayer request</span>
                                                    </v-col>
                                                </v-row>
                                            </v-col>
                                            <v-col cols="7" lg="3" sm="3" align-self="center">
                                                <v-row justify="center" align-self="center">
                                                    <v-col cols="12" class="subone">
                                                        <span>Follow us</span>
                                                    </v-col>
                                                    <v-col cols="12" class="subtwo">
                                                        <span>Instagram</span>
                                                    </v-col> <v-col cols="12" class="subtwo">
                                                        <span>Facebook</span>
                                                    </v-col> <v-col cols="12" class="subtwo">
                                                        <span>Twitter</span>
                                                    </v-col>
                                                </v-row>
                                            </v-col>
                                        </v-row>
                                    </v-col>
                                </v-row>
                            </v-col>
                        </v-row>
                    </v-col>
                </v-row>
            </v-col>
        </v-row>
        <v-row justify="center" class="ftr" style="background-color: rgba(11, 70, 145, 1)">
            <v-col class="text-center ftr" cols="12" lg="7" align-self="center">
                <span style="color: white">© St Antony's Pilgrim Church Chengalam
                    <span>|</span> Designed & Developed by
                    <span style="color: #f4c671">Leopard Tech Labs Pvt Ltd</span></span>
            </v-col>
        </v-row>
    </div>
    <div class="hidden-md-and-up">
        <v-row justify="center" style="background-color: rgba(15, 30, 48, 1);">
            <v-col cols="12" class="py-2">
                <v-row justify="center">
                    <v-col cols="10">
                        <v-row justify="center">
                            <v-col cols="12">
                                <v-row justify="center">
                                    <v-col cols="6" lg="4" sm="12" class="pa-0 text-left" align-self="start">
                                        <v-row justify="start">
                                            <v-col cols="12" sm="12" class="text-left" align-self="center">
                                                <v-row justify="center">
                                                    <v-col cols="12" sm="6">
                                                        <v-img src="../../assets/images/logofooter.png">
                                                </v-img>
                                                    </v-col>
                                                </v-row>
                                            </v-col>
                                            <v-col cols="12" sm="12" class="text-center interregular headname" align-self="center">
                                                <span>St Antony's Pilgrim Church Chengalam</span>
                                            </v-col>
                                            <v-col cols="12"  sm="12" class="text-center interregular subhead" align-self="center">
                                                <span>Chengalam, Kottayam, Kerala <br>Pin-686 585</span>

                                            </v-col>
                                            <v-col cols="12"  sm="12" class="text-center interregular subhead" align-self="center">
                                                <span>+91
                                                    8281564332<br>0481-270 4332
                                                </span>
                                            </v-col>
                                            <v-col cols="12"  sm="12" class="text-center interregular subhead" align-self="center">
                                                <span>sacchengalam@gmail.com</span>
                                            </v-col>
                                        </v-row>
                                    </v-col>
                                    <v-col cols="12" lg="8" sm="12" align-self="center">
                                        <v-row justify="center">
                                            <v-col cols="7" lg="3" sm="3" align-self="center">
                                                <v-row justify="center" align-self="center" class="text-center">
                                                    <v-col cols="12" class="subone" @click="redirectToMap">
                                                        <span>Navigation</span>
                                                    </v-col>
                                                    <v-col cols="12" class="subtwo" @click="redirectToHome">
                                                        <span>Home</span>
                                                    </v-col> <v-col cols="12" class="subtwo" @click="redirectToGallery">
                                                        <span>Gallery</span>
                                                    </v-col> <v-col cols="12" class="subtwo">
                                                        <span>News & events</span>
                                                    </v-col>
                                                </v-row>
                                            </v-col>
                                            <v-col cols="7" lg="3" sm="3" align-self="center">
                                                <v-row justify="center" align-self="center" class="text-center">
                                                    <v-col cols="12" class="subone" @click="redirectToAbout">
                                                        <span>About</span>
                                                    </v-col>
                                                    <v-col cols="12" class="subtwo" @click="redirectToContactus">
                                                        <span>Contact Us</span>
                                                    </v-col>
                                                    <v-col cols="12" class="subtwo" @click="redirectToTimings">
                                                        <span>Timings</span>
                                                    </v-col>
                                                    <v-col cols="12" class="subtwo" @click="redirectToPrayerreq">
                                                        <span>Prayer request</span>
                                                    </v-col>
                                                </v-row>
                                            </v-col>
                                            <v-col cols="7" lg="3" sm="3" align-self="center" class="text-center">
                                                <v-row justify="center" align-self="center">
                                                    <v-col cols="12" class="subone">
                                                        <span>Follow us</span>
                                                    </v-col>
                                                    <v-col cols="12" class="subtwo">
                                                        <span>Instagram</span>
                                                    </v-col> <v-col cols="12" class="subtwo">
                                                        <span>Facebook</span>
                                                    </v-col> <v-col cols="12" class="subtwo">
                                                        <span>Twitter</span>
                                                    </v-col>
                                                </v-row>
                                            </v-col>
                                        </v-row>
                                    </v-col>
                                </v-row>
                            </v-col>
                        </v-row>
                    </v-col>
                </v-row>
            </v-col>
        </v-row>
        <v-row justify="center" class="ftr" style="background-color: rgba(11, 70, 145, 1)">
            <v-col class="text-center ftr" cols="12" lg="7" align-self="center">
                <span style="color: white">© St Antony's Pilgrim Church Chengalam
                    <span>|</span> Designed & Developed by
                    <span style="color: #f4c671">Leopard Tech Labs Pvt Ltd</span></span>
            </v-col>
        </v-row>
    </div>
</template>
<script>
export default {
    methods: {
        redirectToTimings() {
            this.$router.push({ name: 'prayertiming' }).then(() => {
                window.scrollTo(0, 0);
            });
        },
        redirectToContactus() {
            this.$router.push({ name: 'contactus' }).then(() => {
                window.scrollTo(0, 0);
            });
        },
        redirectToPrayerreq() {
            this.$router.push({ name: 'prayerreq' }).then(() => {
                window.scrollTo(0, 0);
            });
        },
        redirectToAbout() {
            this.$router.push({ name: 'aboutus' }).then(() => {
                window.scrollTo(0, 0);
            });
        },
        redirectToGallery() {
            this.$router.push({ name: 'gallery' }).then(() => {
                window.scrollTo(0, 0);
            });
        },
        redirectToHome() {
            this.$router.push({ name: 'home' }).then(() => {
                window.scrollTo(0, 0);
            });
        },
        redirectToMap() {
            this.$router.push({ name: 'contactus' }).then(() => {
                window.scrollTo(0, 0);
            });
        }
    }

};
</script>
<style>
.headname {
    font-size: 17px;
    font-weight: 700;
    color: white;
    text-align: justify;
}

.subhead {
    font-size: 14px;
    font-weight: 400;
    color: white;
    text-align: justify;
}

.subone {
    font-family: interregular;
    font-weight: 600;
    font-size: 18px;
    color: white;
}

.subtwo {
    font-family: interregular;
    font-weight: 400;
    font-size: 16px;
    color: rgba(255, 255, 255, 1);
}
</style>