<template>
  <v-app>
    <HeaderPage />
    <v-main>
      <router-view />
    </v-main>
    <FooterPage />
  </v-app>
</template>

<script>
import "../src/assets/style/font.css";
import "../src/assets/style/style.css";
import HeaderPage from "././components/Common/HeaderPage";
import FooterPage from "././components/Common/FooterPage";
export default {
  name: "App",
  components: {
    HeaderPage,
    FooterPage,
  },

  data: () => ({
    //
  }),
};
</script>
