<template>
    <div class="hidden-sm-and-down">
        <v-row justify="center">
            <v-col cols="12">
                <v-img src="../../assets/images/introbg.png">
                    <v-row justify="center">
                        <v-col cols="12" align-self="center">
                            <v-row justify="center">
                                <v-col cols="10" align-self="center">
                                    <v-row justify="center">
                                        <v-col cols="12" align-self="center">
                                            <v-row justify="center">
                                                <v-col cols="12" lg="6" sm="6" md="11" align-self="center"
                                                    class=" pt-md-16"><v-img
                                                        src="../../assets/images/aboutimg.jpg" alt="St. Antony's Pilgrim Church Chengalam, Kottayam, Kerala, India"></v-img></v-col>
                                                <v-col cols="12" lg="6" sm="6" md="12">
                                                    <v-row justify="start" class="pt-md-16">
                                                        <v-col cols="12" class="st1">
                                                            <span>ABOUT</span>
                                                        </v-col>
                                                        <v-col cols="12" class="st2">
                                                            <span>PADUVA OF KERALA
                                                            </span>
                                                        </v-col>
                                                        <v-col cols="11" sm="10" md="3" lg="5"
                                                            class="pa-0 px-3 text-center">
                                                            <v-progress-linear color="yellow darken-2"
                                                                model-value="100"></v-progress-linear>
                                                        </v-col>
                                                        <v-col cols="12" class="st3">
                                                            <span id="readMore">St Antony's church Chengalam is one of the
                                                                famous early pilgrim centre of Kerala,under Kanjirapally
                                                                diocese. The village of Chengalam is located at the east
                                                                side of Kottayam district between the three panchayaths
                                                                namely Pallickathode, Elangulam and Akalakunnam. Naturally
                                                                Chengalam called "Chengalam East" which is originally belonged
                                                                to Akalakunnam panchayath. The village of Chengalam is also
                                                                called 'The Paduva of Kerala' after the famous St Antony's
                                                                church which is dedicated to Saint Antony of Paduva. From
                                                                the early days of the church, Chengalam and its people had
                                                                been blessed with the power and blessings of christ through
                                                                St. Antony.. <span class="st4" @click="redirectToAbout" @mouseover="changeCursor" @mouseout="restoreCursor">Read more</span></span>
                                                        </v-col>
                                                    </v-row>
                                                </v-col>
                                            </v-row>
                                        </v-col>
                                    </v-row>
                                </v-col>
                            </v-row>
                        </v-col>
                    </v-row>
                </v-img>
            </v-col>
        </v-row>
        <v-row justify="center">
            <v-col cols="12" class="pb-9">
                <v-img src="../../assets/images/webadone.jpg"></v-img>
            </v-col>
        </v-row>
    </div>
    <div class="hidden-md-and-up">
        <v-row justify="center" class="pt-6">
            <v-col cols="12">
                <v-row justify="center">
                    <v-col cols="12" align-self="center">
                        <v-row justify="center">
                            <v-col cols="10" align-self="center">
                                <v-row justify="center">
                                    <v-col cols="12" align-self="center">
                                        <v-row justify="center" class="py-sm-9">
                                            <v-col cols="12" lg="6" sm="6" md="11" align-self="center"
                                                class="pt-lg-16 pt-md-16"><v-img
                                                    src="../../assets/images/aboutimg.jpg" alt="St. Antony's Pilgrim Church Chengalam, Kottayam, Kerala, India"></v-img></v-col>
                                            <v-col cols="12" lg="6" sm="6" md="12">
                                                <v-row justify="start" class="pt-md-16">
                                                    <v-col cols="12" class="st1">
                                                        <span>ABOUT</span>
                                                    </v-col>
                                                    <v-col cols="12" class="st2">
                                                        <span>PADUVA OF KERALA
                                                        </span>
                                                    </v-col>
                                                    <v-col cols="10" sm="9" md="10" lg="10" class="pa-0 px-3 text-center">
                                                        <v-progress-linear color="yellow darken-2"
                                                            model-value="100"></v-progress-linear>
                                                    </v-col>
                                                    <v-col cols="12" class="st3">
                                                        <span id="readMore">St Antony's church Chengalam is one of the
                                                                famous early pilgrim centre of Kerala,under Kanjirapally
                                                                diocese. The village of Chengalam is located at the east
                                                                side of Kottayam district between the three panchayaths
                                                                namely Pallickathode, Elangulam and Akalakunnam. Naturally
                                                                Chengalam called "Chengalam East" which is originally belonged
                                                                to Akalakunnam panchayath. The village of Chengalam is also
                                                                called 'The Paduva of Kerala' after the famous St Antony's
                                                                church which is dedicated to Saint Antony of Paduva. From
                                                                the early days of the church, Chengalam and its people had
                                                                been blessed with the power and blessings of christ through
                                                                St Antony..
                                                            .. <span class="st4" @click="redirectToAbout" @mouseover="changeCursor" @mouseout="restoreCursor">Read more</span></span>
                                                    </v-col>
                                                </v-row>
                                            </v-col>
                                        </v-row>
                                    </v-col>
                                </v-row>
                            </v-col>
                        </v-row>
                    </v-col>
                </v-row>
            </v-col>
        </v-row>
        <v-row justify="center">
            <v-col cols="12" class="pb-9">
                <v-img src="../../assets/images/webadone.jpg"></v-img>
            </v-col>
        </v-row>
    </div>
</template>
<script>
export default {
  methods: {
    redirectToAbout() {
      // Use this.$router.push to redirect to the desired page
      // You can replace "/aboutus" with your actual route
      this.$router.push("/aboutus");

      // Optionally, scroll to the top with smooth behavior
      window.scrollTo({
        top: 0,
        behavior: 'smooth',
      });
    },
    changeCursor() {
      document.getElementById("readMore").style.cursor = "pointer";
    },
    restoreCursor() {
      document.getElementById("readMore").style.cursor = "auto";
    },
  },
};
</script>
<style>
.head {
    font-family: interregular;
    font-size: 24px;
    font-weight: 400;
    color: rgba(255, 255, 255, 1);
}

.headone {
    font-family: latoregular;
    font-size: 55px;
    font-weight: 800;
    color: rgba(255, 255, 255, 1);
}

.headtwo {
    font-family: interregular;
    font-size: 18px;
    font-weight: 500;
    color: rgba(255, 255, 255, 1);
}

.bg {
    background: linear-gradient(180deg, rgba(251, 252, 253, 1) 0%, #FFFFFF 64.42%);
}


.hdtw {
    font-family: interregular;
    font-size: 22px;
    font-weight: 700;
    color: rgba(255, 255, 255, 1);
}

.st1 {
    font-family: interregular;
    font-size: 22px;
    font-weight: 700;
    color: rgba(60, 116, 185, 1);
}

.st2 {
    font-family: interregular;
    font-size: 22px;
    font-weight: 600;
    color: rgba(38, 38, 38, 1);
}

.st3 {
    font-family: interregular;
    font-size: 16px;
    font-weight: 500;
    color: rgba(62, 62, 62, 1);
    text-align: justify;
}

.st4 {
    font-family: interregular;
    font-size: 16px;
    font-weight: 700;
    color: rgba(60, 116, 185, 1);
}

.underline {
    text-decoration: underline;
    color: rgba(255, 168, 0, 1);
}</style>