import { createApp } from 'vue'
import App from './App.vue'
import router from './router'
import store from './store'
import axios from 'axios';
import vuetify from './plugins/vuetify'
import { loadFonts } from './plugins/webfontloader'
import VueGtag from "vue-gtag";
import AOS from 'aos'
import 'aos/dist/aos.css'

loadFonts()
const app = createApp(App);
AOS.init({
  offset: 100,
  duration: 1000,
  easing: 'ease-in-out',
  once: false,
});
app.use(AOS);


// axios.defaults.baseURL = 'http://192.168.55.138:5055';
// app.config.globalProperties.baseURL = "http://192.168.55.138:5055";
// app.config.globalProperties.mediaURL = "http://192.168.52.74:5055/file/get/"

axios.defaults.baseURL = 'https://churchapi.leopardtechlabs.in';
app.config.globalProperties.baseURL = "https://churchapi.leopardtechlabs.in";
app.config.globalProperties.mediaURL = "https://churchapi.leopardtechlabs.in/file/get/"
    // app.config.globalProperties.mediaURLs = "churchapi.leopardtechlabs.in/wp?key="

createApp(App)
  .use(router)
  .use(store)
  .use(vuetify)
  .use(VueGtag, {
    config: { id: "G-4R5NLKTQTH"}
  })
  .mount('#app')
