<template>
    <div class="hidden-sm-and-down">
        <v-row justify="center" style="background-color: #244E82;">
            <v-col cols="12" class="pa-0">
                <v-row justify="center">
                    <v-col cols="10" class="py-16">
                        <v-img src="../../assets/images/prayer.png" cover height="100%" width="100%" alt="St. Antony's Pilgrim Church Chengalam, Kottayam, Kerala, India">
                            <v-row justify="center" class="text-left fill-height pt-16 pb-16">
                                <v-col cols="12" align-self="center">
                                    <v-row justify="center">
                                        <v-col cols="6">
                                            <v-row justify="center" class="pl-16">
                                                <v-col cols="12" align-self="center" class="timimg">
                                                    <span>Holy Mass Timings</span>
                                                </v-col>
                                                <v-col cols="12" sm="12" lg="6" md="6">
                                                    <v-row justify="center">
                                                        <v-col cols="12" class="subtiming">
                                                            <span>
                                                                Sundays
                                                            </span>
                                                        </v-col>
                                                        <v-col cols="12" class="timings">
                                                            <span>
                                                                05:30 am, 07:00 am,<br> 09:30 am, 05:30 pm
                                                            </span>
                                                        </v-col>
                                                        <v-col cols="12" class="subtiming">
                                                            <span>
                                                                Tuesdays
                                                            </span>
                                                        </v-col>
                                                        <v-col cols="12" class="timings">
                                                            <span>
                                                                05:30 am : Holy Mass, Novena <br>
                                                                06:45 am : Holy Mass <br>
                                                                11:00 am : Holy Mass, Novena <br>
                                                                05:30 pm : Holy Mass, Novena <br>
                                                            </span>
                                                        </v-col>
                                                    </v-row>
                                                </v-col>
                                                <v-col cols="12" sm="12" lg="6" md="6" class="fill-height"
                                                    align-self="center">
                                                    <v-row justify="start" class="fill-height">
                                                        <v-col cols="12" class="subtiming" align-self="center">
                                                            <span>
                                                                Other Days
                                                            </span>
                                                        </v-col>
                                                        <v-col cols="12" class="timings" align-self="center">
                                                            <span>
                                                                06:15 am : Holy Mass <br>
                                                                05:30 pm : Holy Mass
                                                            </span>
                                                        </v-col>
                                                        <v-col cols="12" class="subtiming" align-self="center">
                                                            <span>First Tuesdays</span>
                                                        </v-col>
                                                        <v-col cols="12" class="timings" align-self="center">
                                                            <span>
                                                                05:30 am : Holy Mass, Novena <br>
                                                                06:45 am : Holy Mass <br>
                                                                11:00 am : Holy Mass, Novena <br>
                                                                05:30 pm : Holy Mass, Novena <br>
                                                                07:00 pm : Candle Prathikshanam<br>
                                                            </span>
                                                        </v-col>
                                                        <v-col cols="4" align-self="center">
                                                            <v-img src="../../assets/images/prayertwo.png" alt="St. Antony's Pilgrim Church Chengalam, Kottayam, Kerala, India"></v-img>
                                                        </v-col>
                                                    </v-row>
                                                </v-col>
                                            </v-row>
                                        </v-col>
                                        <v-col>
                                        </v-col>
                                        <v-col cols="4">
                                            <v-spacer></v-spacer>
                                        </v-col>
                                    </v-row>
                                </v-col>
                            </v-row>
                        </v-img>
                    </v-col>
                </v-row>
            </v-col>
        </v-row>
    </div>
    <div class="hidden-md-and-up">
        <v-row justify="center" style="background-color: #244E82;">
            <v-col cols="12">
                <v-row justify="center">
                    <v-col cols="10">
                        <v-row justify="center">
                            <v-col cols="12" class="text-center timimg">
                                <span>Holy Mass Timings</span>
                            </v-col>
                            <v-col cols="9" sm="4" md="2" lg="1" class="pa-0 px-3 text-center">
                                <v-progress-linear color="yellow darken-2" model-value="100"></v-progress-linear>
                            </v-col>
                            <v-col cols="12">
                                <v-row justify="center">
                                    <v-col cols="12" sm="6">
                                        <v-row justify="center">
                                            <v-col cols="12" class="subtiming text-center">
                                                <span>Sundays</span>
                                            </v-col>
                                            <v-col cols="12" class="timings text-center">
                                                <span>
                                                    05:30 am, 07:00 am,<br> 09:30 am, 05:30 pm
                                                </span>
                                            </v-col>
                                        </v-row>
                                    </v-col>
                                    <v-col cols="12" sm="6">
                                        <v-row justify="center">
                                            <v-col cols="12" class="subtiming text-center">
                                                <span>Other Days</span>
                                            </v-col>
                                            <v-col cols="12" class="timings text-center">
                                                <span>
                                                    06:15 am : Holy Mass <br>
                                                    05:30 pm : Holy Mass
                                                </span>
                                            </v-col>
                                        </v-row>
                                    </v-col>
                                    <v-col cols="12" sm="6">
                                        <v-row justify="center">
                                            <v-col cols="12" class="subtiming text-center">
                                                <span>Tuesday</span>
                                            </v-col>
                                            <v-col cols="12" class="timings text-center">
                                                <span>
                                                    05:30 am : Holy Mass, Novena <br>
                                                    06:45 am : Holy Mass <br>
                                                    11:00 am : Holy Mass, Novena <br>
                                                    05:30 pm : Holy Mass, Novena <br>
                                                </span>
                                            </v-col>
                                        </v-row>
                                    </v-col>
                                    <v-col cols="12" sm="6">
                                        <v-row justify="center">
                                            <v-col cols="12" class="subtiming text-center">
                                                <span>First Tuesday</span>
                                            </v-col>
                                            <v-col cols="12" class="timings text-center">
                                                <span>
                                                    05:30 am : Holy Mass, Novena <br>
                                                    06:45 am : Holy Mass <br>
                                                    11:00 am : Holy Mass, Novena <br>
                                                    05:30 pm : Holy Mass, Novena <br>
                                                    07:00 pm : Candle Prathikshanam<br>
                                                </span>
                                            </v-col>
                                        </v-row>
                                    </v-col>
                                </v-row>
                            </v-col>
                            <v-col cols="4" sm="2">
                                <v-img src="../../assets/images/prayertwo.png" alt="St. Antony's Pilgrim Church Chengalam, Kottayam, Kerala, India"></v-img>
                            </v-col>
                        </v-row>
                    </v-col>
                </v-row>
            </v-col>
        </v-row>
    </div>
</template>
<style>
.timimg {
    color: white;
    font-family: interregular;
    font-size: 20px;
    font-weight: 600;
}

.subtiming {
    color: white;
    font-family: interregular;
    font-size: 18px;
    font-weight: 400;
}

.timings {
    color: white;
    font-family: interregular;
    font-size: 16px;
    font-weight: 400;
    /* text-align: justify; */
}
</style>