<template>
    <div>
        <v-row justify="center" class="pt-16">
            <v-col cols="12">
                <v-row justify="center">
                    <v-col cols="12" class="pa-0">
                        <v-img src="../../assets/images/8.jpg" cover height="100%" width="auto" alt="St. Antony's Pilgrim Church Chengalam, Kottayam, Kerala, India">
                            <v-row justify="center" class="text-left fill-height">
                                <v-col cols="10" align-self="center">
                                    <v-row justify="center" class="fill-height" data-aos="zoom-in" data-aos-once="false">
                                        <v-col cols="12" align-self="center" class="pt-16">
                                            <span style="color: rgba(255, 255, 255, 1);font-size: 25px; font-weight: 400;">Welcome to</span>
                                        </v-col>
                                        <v-col cols="12" class="headertext">
                                            <span>
                                                St Antony’s Pilgrim Church, Chengalam
                                            </span>
                                        </v-col>
                                        <v-col cols="12" class="headertexttwo">
                                            <span>The famous Pilgrimage Center of Saint Antony in South India
                                            </span>
                                        </v-col>

                                    </v-row>
                                </v-col>
                            </v-row>
                        </v-img>
                    </v-col>
                </v-row>
                <!-- <v-row justify="center" class="bghome">
                    <v-col cols="12">
                        <v-row justify="center" class="py-10">
                            <v-col cols="10">
                                <v-row justify="start">
                                    <v-col cols="12" lg="10">
                                        <span class="hdtw">Lorem ipsum dolor sit amet consectetur.</span><br>
                                        <span class="headtwo">Lorem ipsum dolor sit amet consectetur. Purus lorem ac
                                            curabitur in
                                            tortor condimentum. Aliquam pharetra cursus nec at lectus scelerisque. Quis
                                            malesuada
                                            sed turpis amet dictum condimentum risus. Massa ut leo in turpis.</span>
                                    </v-col>
                                    <v-col cols="12" lg="2" align-self="center">
                                        <v-btn color="red">Watch Live&nbsp;<v-icon>mdi-antenna</v-icon></v-btn>
                                    </v-col>
                                </v-row>
                            </v-col>
                        </v-row>
                    </v-col>
                </v-row> -->
                <v-row justify="center" class="bghome">
                    <v-col cols="12">
                        <v-row justify="center">
                            <v-col cols="10">
                                <v-row justify="center">
                                    <v-col cols="12">
                                        <v-row justify="center">
                                            <v-col cols="12" lg="3" sm="3">
                                                <v-row justify="center">
                                                    <v-col cols="12">
                                                        <v-img src="../../assets/images/iphonefull.png" width="100%"
                                                            height="auto" alt="St. Antony's Pilgrim Church Chengalam, Kottayam, Kerala, India"></v-img>
                                                    </v-col>
                                                </v-row>
                                            </v-col>
                                            <v-col cols="12" lg="6" sm="6" class="py-lg-10 py-md-10 py-sm-10">
                                                <v-row justify="center">
                                                    <v-col cols="12" class="text-center ads">
                                                        <span>Introducing our new Church Directory App</span>
                                                    </v-col>
                                                    <v-col cols="12" class="text-center adssub">
                                                        <span>Now available on </span>
                                                    </v-col>
                                                    <v-col cols="12">
                                                        <v-row justify="center">
                                                            <v-col cols="12" lg="4" sm="4">
                                                                <a
                                                                    href="https://play.google.com/store/apps/details?id=com.leopardtechlabs.churchdirectory" target="_blank">
                                                                    <v-img src="../../assets/images/playsore.png" alt="St. Antony's Pilgrim Church Chengalam, Kottayam, Kerala, India"></v-img>
                                                                </a>
                                                            </v-col>

                                                            <v-col cols="12" lg="4" sm="4">
                                                                <a
                                                                    href="https://apps.apple.com/in/app/st-antonys-church-chengalam/id6476023538" target="_blank">
                                                                    <v-img src="../../assets/images/appstore.png" alt="St. Antony's Pilgrim Church Chengalam, Kottayam, Kerala, India"></v-img>
                                                                </a>
                                                            </v-col>
                                                        </v-row>
                                                    </v-col>
                                                </v-row>
                                            </v-col>
                                            <v-col cols="12" lg="3" sm="3" data-aos="slide-right">
                                                <v-row justify="center">
                                                    <v-col cols="12">
                                                        <v-img src="../../assets/images/logoad.png" alt="St. Antony's Pilgrim Church Chengalam, Kottayam, Kerala, India"></v-img>
                                                    </v-col>
                                                </v-row>
                                            </v-col>
                                        </v-row>
                                    </v-col>
                                </v-row>
                            </v-col>
                        </v-row>
                    </v-col>
                </v-row>
            </v-col>
        </v-row>
    </div>
</template>
<style>
.headerrrrrrrrr {
    font-size: 24px;
    font-weight: 400;
    color: rgba(255, 255, 255, 1);
}

.headertext {
    font-size: 45px;
    font-weight: 800;
    color: rgba(255, 255, 255, 1);
}

.headertexttwo {
    font-size: 25px;
    font-weight: 500;
    color: rgba(255, 255, 255, 1);
    padding-bottom: 30px;
}

.bghome {
    background: linear-gradient(180deg, #094875 0%, #1B5F8F 100%);
}

.hdtw {
    font-family: interregular;
    font-size: 22px;
    font-weight: 700;
    color: rgba(255, 255, 255, 1);
}

.st1 {
    font-family: interregular;
    font-size: 22px;
    font-weight: 700;
    color: rgba(60, 116, 185, 1);
}

.st2 {
    font-family: interregular;
    font-size: 22px;
    font-weight: 600;
    color: rgba(38, 38, 38, 1);
}

.st3 {
    font-family: interregular;
    font-size: 18px;
    font-weight: 500;
    color: rgba(62, 62, 62, 1);
}

.st4 {
    font-family: interregular;
    font-size: 18px;
    font-weight: 700;
    color: rgba(60, 116, 185, 1);
}

.underline {
    text-decoration: underline;
    color: rgba(255, 168, 0, 1);
}

.ads {
    font-family: interregular;
    font-size: 30px;
    font-weight: 500;
    color: white;
}

.adssub {
    font-family: interregular;
    font-size: 18px;
    font-weight: 500;
    color: white;
}
</style>