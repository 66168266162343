<template>
    <div class="hidden-sm-and-down pa-0 bgsecond">
        <v-row justify="center">
            <v-col cols="12">
                <v-row justify="center">
                    <v-col cols="12" align-self="center">
                        <v-row justify="center">
                            <v-col cols="10" align-self="center">
                                <v-row justify="center" class="pb-15">
                                    <v-col cols="12" align-self="center">
                                        <v-row justify="center">
                                            <v-col cols="12" lg="6" sm="6" md="11" align-self="center"
                                                class=" pt-md-16"><v-img
                                                    src="../../assets/images/nw2.png" alt="St. Antony's Pilgrim Church Chengalam, Kottayam, Kerala, India"></v-img></v-col>
                                            <v-col cols="12" lg="6" sm="6" md="12">
                                                <v-row justify="start" class="pt-md-16">
                                                    <v-col cols="12" class="msgheaddddddddddddd">
                                                        <span>PERPETUAL ADORATION CHAPEL
                                                        </span>
                                                    </v-col>
                                                    <v-col cols="11" sm="10" md="5" lg="7" class="pa-0 px-3 text-center">
                                                        <v-progress-linear color="yellow darken-2"
                                                            model-value="100"></v-progress-linear>
                                                    </v-col>
                                                    <v-col cols="12" class="msg">
                                                        <span id="readMore">Adoration is the first
                                                            attitude of man acknowledging that he is a creature before
                                                            his Creator.In the adoration Chappel relics of 12 Saints are
                                                            present.People from far and wide visits this Chapel and
                                                            receives God's favours.The faith of the people is
                                                            unbelievable and they spend day hours in the Chapel.Chapel
                                                            is open from 07.30 am in the morning till 07.00 pm in the
                                                            evening.The tears of many has made the Chapel a “Holy
                                                            ground” where the Lord sits and walks with the people.
                                                            Adoration is homage of the spirit to the “King of Glory,”
                                                            respectful silence in the presence of the “ever greater”
                                                            God. Adoration of the thrice-holy and sovereign God of love
                                                            blends with humility and gives assurance to our
                                                            supplications. —Catechism of the Catholic Church, no. 2628.
                                                            The visit to the Blessed Sacrament is a great treasure of
                                                            the Catholic faith. It nourishes social love and gives us
                                                            opportunities for adoration and thanksgiving, for reparation
                                                            and supplication. Benediction of the Blessed Sacrament,
                                                            Exposition an Adoration of the Blessed Sacrament Holy Hours
                                                            and Eucharistic processions are likewise precious element of
                                                            your heritage-in full accord with the teaching of the Second
                                                            Vatican Council. (Pope John Paul II, Phoenix
                                                            Park, Ireland 1979.)
                                                        </span>
                                                    </v-col>
                                                </v-row>
                                            </v-col>
                                        </v-row>
                                    </v-col>
                                </v-row>
                            </v-col>
                        </v-row>
                    </v-col>
                </v-row>
            </v-col>
        </v-row>
    </div>
    <div class="hidden-md-and-up bgsecond">
        <v-row justify="center">
            <v-col cols="12">
                <v-row justify="center">
                    <v-col cols="10">
                        <v-row justify="center" class="py-12">
                            <v-col cols="12" sm="6">
                                <v-img src="../../assets/images/nw2.png" alt="St. Antony's Pilgrim Church Chengalam, Kottayam, Kerala, India"></v-img>
                            </v-col>
                            <!-- <v-col cols="12" class="text-center name"><span>Vicar, Fr.Philip Vaikathukaran</span></v-col> -->
                            <v-col cols="12" class="text-center msgheaddddddddddddd"><span>PERPETUAL ADORATION
                                    CHAPEL</span></v-col>
                            <v-col cols="9" sm="10" md="10" lg="7" class="pa-0 px-3 text-start">
                                <v-progress-linear color="yellow darken-2" model-value="100"></v-progress-linear>
                            </v-col>
                            <v-col cols="12" class=" msg"><span>Adoration is the
                                    first attitude of man acknowledging that he is a creature before
                                    his Creator. In the adoration Chappel relics of 14 Saints are
                                    present. People from far and wide visits this Chapel and
                                    receives God's favours. The faith of the people is
                                    unbelievable and they spend day hours in the Chapel. Chapel
                                    is open from 07.30 am in the morning till 07.00 pm in the
                                    evening. The tears of many has made the Chapel a “Holy
                                    ground” where the Lord sits and walks with the people.
                                    Adoration is homage of the spirit to the “King of Glory,”
                                    respectful silence in the presence of the “ever greater”
                                    God. Adoration of the thrice-holy and sovereign God of love
                                    blends with humility and gives assurance to our
                                    supplications. —Catechism of the Catholic Church, no. 2628.
                                    The visit to the Blessed Sacrament is a great treasure of
                                    the Catholic faith. It nourishes social love and gives us
                                    opportunities for adoration and thanksgiving, for reparation
                                    and supplication. Benediction of the Blessed Sacrament,
                                    Exposition an Adoration of the Blessed Sacrament Holy Hours
                                    and Eucharistic processions are likewise precious element of
                                    your heritage-in full accord with the teaching of the Second
                                    Vatican Council. (Pope John Paul II, Phoenix
                                    Park, Ireland 1979.)</span></v-col>
                        </v-row>
                    </v-col>
                </v-row>
            </v-col>
        </v-row>
    </div>
</template>
<style>
.msg {
    font-family: interregular;
    font-weight: 500;
    font-size: 16px;
    color: white;
    text-align: justify;
}

.msgheaddddddddddddd {
    font-family: interregular;
    font-weight: 600;
    font-size: 22px;
    color: white;
}

.name {
    font-family: interregular;
    font-weight: 400;
    font-size: 16px;
    color: white;
}

.bgsecond {
    background: linear-gradient(180deg, #094875 0%, #1B5F8F 100%);
}
</style>