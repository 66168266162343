<template>
    <div>
        <v-row no-gutters justify="center" class="fill-height">
            <v-col cols="12">
                <firstSection/>
                <secondSection/>
                <thirdSection/>
                <fourthSection/>
                <fifthSection/>
                <sixthSection/>
            </v-col>
        </v-row>
    </div>
</template>
<script>
import firstSection from "../Home/firstSection.vue"
import secondSection from "../Home/secondSection.vue"
import thirdSection from "../Home/thirdSection.vue"
import fourthSection from "../Home/fourthSection.vue"
import fifthSection from "../Home/fifthSection.vue"
import sixthSection from "../Home/sixthSection.vue"



export default{
    components :{
        firstSection,
        secondSection,
        thirdSection,
        fourthSection,
        fifthSection,
        sixthSection,



    },
};
</script>