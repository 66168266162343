<template>
  <div>
    <v-row justify="center" class="pb-16">
      <v-col cols="12">
        <v-row justify="center" class="pt-16">
          <v-col cols="12" class="text-center hed">
            <span>NEWS & EVENTS</span>
          </v-col>
          <v-col cols="5" sm="2" md="2" lg="1" class="pa-0 px-3 text-center">
            <v-progress-linear color="yellow darken-2" model-value="100"></v-progress-linear>
          </v-col>
        </v-row>
        <v-row justify="center">
          <v-col cols="10" class="text-right">
            <v-btn icon size="small" @click="prevSlide">
              <v-icon>mdi-chevron-left</v-icon>
            </v-btn>&nbsp;&nbsp;
            <v-btn icon size="small" @click="nextSlide">
              <v-icon>mdi-chevron-right</v-icon>
            </v-btn>
          </v-col>
        </v-row>

        <v-row justify="center">
          <v-col cols="10" style="background-color: #f8f8f8">
            <v-carousel v-model="currentIndex" cycle hide-delimiters class="custom-carousel" :show-arrows="false"
              height="auto">
              <v-carousel-item v-for="(item, index) in alertList" :key="index">
                <v-row justify="center" class="pa-5">
                  <v-col cols="12" sm="6" md="3" lg="6" v-if="item.photo">
                    <v-img :src="'https://churchapi.leopardtechlabs.in/file/get/' + item.photo" alt="St. Antony's Pilgrim Church Chengalam, Kottayam, Kerala, India"></v-img>
                  </v-col>
                  <v-col v-else>
                    <v-img src="../../assets/images/noimage.jpg" alt="St. Antony's Pilgrim Church Chengalam, Kottayam, Kerala, India"></v-img>
                  </v-col>
                  <v-col cols="12" sm="6" md="3" lg="6" align-self="center">
                    <v-row justify="center">
                      <v-col><span>{{ item.title }}</span></v-col>
                      <v-col cols="12">
                        <span v-html="item.description"> </span>
                      </v-col>
                      <v-col cols="12">
                        <v-row justify="center" v-if="item.files">
                          <v-col v-for="(item2, k) in item.files" :key="k" cols="12" align-self="center"
                            class="text-center">
                            <span v-if="item2.split('.').pop() == 'pdf' ||
                              item2.split('.').pop() == 'docx'
                              ">
                              <a target="_blank" :href="'https://churchapi.leopardtechlabs.in/file/get/' +
                                item2
                                ">
                                <v-btn color="#3CB043" dark><span style="
                              text-transform: none;
                              font-family: interregular;
                              font-size: 12px;
                            ">Download</span></v-btn></a></span>
                            <span v-else>
                              <v-btn @click="(doc = item2), (dialog = true)" color="#3CB043" dark>
                                <span style="
                              text-transform: none;
                              font-family: interregular;
                              font-size: 12px;
                            ">View Image</span>
                              </v-btn>
                            </span>
                          </v-col>
                        </v-row>
                      </v-col>
                    </v-row>
                  </v-col>
                </v-row>

              </v-carousel-item>
            </v-carousel>
          </v-col>
        </v-row>
      </v-col>
    </v-row>
    <!-- <v-row justify="center" class="pb-16">
        <v-col cols="12" align-self="center" class="text-center">
          <v-btn color="red" @click="viewMore">View More</v-btn>
        </v-col>
      </v-row> -->
    <v-dialog persistent v-model="dialog" width="70%">
      <v-card>
        <v-toolbar dense dark color="#7CB9E8">
          <v-spacer></v-spacer>
          <v-btn right icon dark @click="(doc = null), (dialog = false)">
            <v-icon>mdi-close</v-icon>
          </v-btn>
        </v-toolbar>
        <v-card-title class="text-h5 grey lighten-2"> Document </v-card-title>
        <v-card-text v-if="doc">
          <v-img :src="'https://churchapi.leopardtechlabs.in/file/get/' + doc" alt="St. Antony's Pilgrim Church Chengalam, Kottayam, Kerala, India"></v-img>
        </v-card-text>
        <v-divider></v-divider>
        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn outlined small color="red" text @click="(dialog = false), (doc = null)">
            Close
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
  </div>
</template>
  
<script>
import axios from "axios";

export default {
  data() {
    return {
      docs: [],
      doc: null,
      dialog: false,
      currentIndex: 0,
      alertList: [],
    };
  },
  methods: {
    getData() {
      this.appLoading = true;
      axios({
        method: "get",
        url: "/notice/list",
        // headers: {

        // },
        // params:{

        // }
      })
        .then((response) => {
          this.appLoading = false;
          if (response.data.status == true) {
            this.alertList = response.data.data;
          } else {
            this.msg = response.data.msg;
            this.showsnackbar = true;
          }
        })
        .catch((err) => {
          console.log(err);
        });
    },
    nextSlide() {
      console.log("Next slide");
      this.currentIndex = (this.currentIndex + 1) % this.alertList.length;
    },
    prevSlide() {
      console.log("Previous slide");
      this.currentIndex =
        (this.currentIndex - 1 + this.alertList.length) % this.alertList.length;
    },
    viewMore() {
      // console.log("View more clicked");
    },
  },
  mounted() {
    this.getData();
  },
};
</script>
  
<style>
.hed {
  font-family: interregular;
  font-weight: 700;
  font-size: 22px;
  color: #3c74b9;
}

.custom-carousel .v-carousel-controls {
  display: none !important;
}
</style>
  