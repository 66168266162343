<template>
  <div>
    <v-row justify="center" class="pt-16">
      <v-col cols="12">
        <v-row justify="center">
          <v-col cols="10">
            <v-row justify="center">
              <v-col cols="12" class="text-center headone">
                <span style="color: #3C74B9;">MEDIA GALLERY</span>
              </v-col>
              <v-col cols="9" sm="3" md="2" lg="2" class="pa-0 px-3 text-center">
                <v-progress-linear color="yellow darken-2" model-value="100"></v-progress-linear>
              </v-col>
            </v-row>
            <v-row justify="center">
              <v-col v-for="(image, index) in imageList" :key="index" cols="12" md="4" sm="6" xs="12" class="mb-4">
                <div class="image-container" @mouseover="onImageHover(index)" @mouseleave="onImageLeave(index)"
                  @click="openImage(index)">
                  <v-img :src="image.url" class="img-fluid" alt="St. Antony's Pilgrim Church Chengalam, Kottayam, Kerala, India"></v-img>
                  <div v-if="image.hovered" class="overlay-text">View Image</div>
                </div>
              </v-col>
            </v-row>
          </v-col>
        </v-row>
      </v-col>
    </v-row>
    <v-row justify="center" class="pb-16">
      <v-col cols="12" align-self="center" class="text-center">
        <v-btn color="red" @click="redirectToAnotherPage">View More</v-btn>
      </v-col>
    </v-row>
    <!-- Modal for displaying enlarged image -->
    <v-dialog v-model="showModal" max-width="800">
      <v-img :src="selectedImage.url" class="enlarged-image" alt="St. Antony's Pilgrim Church Chengalam, Kottayam, Kerala, India"></v-img>
    </v-dialog>
  </div>
</template>
  
<script>
export default {
  data() {
    return {
      imageList: [
        { url: require("@/assets/images/img6.jpg"), hovered: false },
        { url: require("@/assets/images/img1.jpg"), hovered: false },
        { url: require("@/assets/images/img2.jpg"), hovered: false },
        { url: require("@/assets/images/img3.jpg"), hovered: false },
        { url: require("@/assets/images/img4.jpg"), hovered: false },
        { url: require("@/assets/images/img5.jpg"), hovered: false },
      ],
      showModal: false,
      selectedImage: null,
    };
  },
  methods: {
    openImage(index) {
      this.selectedImage = this.imageList[index];
      this.showModal = true;
    },
    onImageHover(index) {
      this.imageList[index].hovered = true;
    },
    onImageLeave(index) {
      this.imageList[index].hovered = false;
    },
    redirectToAnotherPage() {
      this.$router.push('/gallery');
      window.scrollTo({
        top: 0,
        behavior: 'smooth',
      });
    },
  },
};
</script>
  
<style>
.headone {
  font-family: interregular;
  font-weight: 700;
  font-size: 22px;
}

.img-fluid {
  max-width: 100%;
  height: auto;
}

.image-container {
  position: relative;
  overflow: hidden;
}

.overlay-text {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  color: white;
  font-size: 18px;
  font-weight: bold;
  opacity: 0;
  transition: opacity 0.3s;
}

.image-container:hover .overlay-text {
  opacity: 1;
}

.image-container:hover img {
  filter: brightness(70%);
  /* Adjust the brightness value as needed */
}

.enlarged-image {
  width: 100%;
  height: auto;
}
</style>
  